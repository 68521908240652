export const TOKEN = 'auth:token';

export const ESTOQUE_STORAGE = {
  createFrom: 'estoque:create-from',
  createBefore: 'estoque:create-before',
  page: 'estoque:page',
  sort: 'estoque:sort',
  search: 'estoque:search',

};

export const LOTES_STORAGE = {
  createFrom: 'lotes:create-from',
  createBefore: 'lotes:create-before',
  prefixo: 'lotes:prefixo',
  page: 'lotes:page',
  sort: 'lotes:sort',
  search: 'lotes:search',
  textofiltro: 'lotes:textofiltro',

};

export const PRODUCAO_STORAGE = {
  createFrom: 'producao:create-from',
  createBefore: 'producao:create-before',
  page: 'producao:page',
  sort: 'producao:sort',
  search: 'producao:search',

};

export const CONSULTA_STORAGE = {
  statusFila: 'status-fila',
  tipoFila: 'tipo-fila',
};

export const CADASTRO_STORAGE = {
  statusFila: 'status-fila',
  tipoFila: 'tipo-fila',
};

export const MODULOS_STORAGE = {
  modulos: 'permitidos',
};

export const USER_STORAGE = {
  userId: 'id-usuario',
  trocasenha: 'troca-senha',
  instrucoes: 'troca-senha-instrucoes',
};

export const A_PAGAR_STORAGE = {
  createFrom: 'apagar:create-from',
  createBefore: 'apagar:create-before',
  parametro: 'apagar:parametro',
  page: 'apagar:page',
  sort: 'apagar:sort',
  search: 'apagar:search',
  textofiltro: 'apagar:textofiltro',
};

export const A_RECEBER_STORAGE = {
  createFrom: 'areceber:create-from',
  createBefore: 'areceber:create-before',
  parametro: 'areceber:parametro',
  page: 'areceber:page',
  sort: 'areceber:sort',
  search: 'areceber:search',
  textofiltro: 'areceber:textofiltro',
};
