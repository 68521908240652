import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import users from './modules/users';
import producao from './modules/producao';
import estoque from './modules/estoque';
import empresa from './modules/empresa';
import permissao from './modules/permissao';
import controlesequencia from './modules/controlesequencia';
import contrato from './modules/contrato';
import modulos from './modules/modulos';
import documentopagar from './modules/documentopagar';
import documentoreceber from './modules/documentoreceber';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    async $_setup({ dispatch }) {
      await dispatch('auth/$_setup');
    },
  },

  modules: {
    auth,
    users,
    producao,
    estoque,
    empresa,
    permissao,
    controlesequencia,
    contrato,
    modulos,
    documentopagar,
    documentoreceber,
  },
});
